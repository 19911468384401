export function tokenize(name, delimiter = '_') {
  return name
    .replace(/[':,.!?()\-+]/g, '')
    .replace(/ /g, delimiter)
    .toLowerCase();
}

// June 25, 2020
// November 29, 2022

export const STORIES = [
  {
    name: 'The Warrior of Westfall',
    released: 'June 25, 2020',
    url: 'https://alexmorrise.com/the-warrior-of-westfall',
    image: 'https://alexmorrise.com/warrior-of-westfall-images/cover.svg',
  },
  {
    name: "Shut Up, It's Christmas",
    released: 'November 29, 2022',
    url: 'https://alexmorrise.com/shut-up-its-christmas',
    image: 'https://alexmorrise.com/shut-up-its-christmas/suic_cover.svg',
  },
];

export const ALBUMS = [
  {
    name: 'Lego Guy',
    released: 'July 5, 2011',
    tracks: [
      'Decide',
      'This Way',
      'Destined to Be Lonely',
      'Lego Guy',
      'I Hate Me, Too',
      'You Bother Me',
      'The Sky Is Falling',
      "You Can't Take It from Me, Jolin",
      "Hey, Man, What's Wrong with Your Car?",
      "Let's Go to the Sun",
      'Across the River',
    ],
  },
  {
    name: 'Friends',
    released: 'May 6, 2013',
    tracks: [
      "It's the End of the World",
      '(Andy) Walk Away',
      'Friends',
      'Oh, Miranda',
      'The Three of Us',
      'Failing You',
      'About Love',
      "It's Me",
      'At the End of the World',
      'In My Arms',
    ],
  },
  {
    name: 'Mission to Mars',
    released: 'October 12, 2015',
    tracks: [
      'Ghosts (In the Sunshine)',
      'You Let Me Go Away',
      'Robot Girl',
      '(A Matter of) Time',
      'Mission to Mars',
      'Ctrl+Alt+Delete',
      'Dwayne Johnson',
      'Waiting...',
      'The Zookeeper',
      'Tom and the Andersens',
      'Music About Nothing',
    ],
  },
  {
    name: 'The Mathematician',
    released: 'June 2, 2017',
    tracks: [
      'Prelude',
      'This Is the Moment',
      "(I Don't) Like Dancing",
      'Galadriel',
      'Wanting Me',
      'Before We Fell In Love',
      "That's As Much As You Need to Know",
      'Jessica',
      'A Long Way Down',
      'The Mathematician',
    ],
  },
  {
    name: 'Country for You',
    released: 'Coming Soon',
    tracks: [
      'Country for You',
      'Colorado Springs',
      'Arizona',
      'How Could I Not',
      'Soulmates',
      'The Man I Used to Be',
      'Exit Interview (Not Doing Fine)',
      'The Great Guru',
      'Hold on Tight',
      'Leave Me Alone',
      'Only a Song',
    ],
  },
];

export const OTHER = [
  {
    name: 'Music About Nothing',
    released: 'October 14, 2009',
    tracks: [
      'The Army of Shiz',
      "It's Time to Talk Me Down",
      'Skulduggery',
      'Your Heart',
      'The Theory of the String',
      '5:00',
      'Valley Song',
      'Nigel and Brian',
      'Jump (the Shark)',
      'The Space-Time Continuum Is Wrong',
      'This Song is So Random',
      'Continental',
    ],
    remoteImage: true,
  },
  {
    name: 'Albert II: The Voyage Home',
    released: 'November 16, 2009',
    tracks: ['Countdown', 'Liftoff', 'Space', 'Return', 'Descent'],
    remoteImage: true,
  },
  {
    name: 'Undying Love',
    released: 'September 6, 2010',
    tracks: [
      "Sarah's a Zombie Now",
      'Zombie Movie',
      "Something's Strange",
      'Mr. Zombie President',
      'Take It from Me',
      'The Train',
      'Make It out Alive',
      "(Don't) Eat My Brain",
      'Falling to Pieces',
      'Zombie Blues',
      'Run for My Life',
      'Bring Me Down',
      'Save the Day',
      'Undying Love',
    ],
    remoteImage: true,
  },
  {
    name: 'The Light',
    released: 'December 27, 2010',
    tracks: [
      'The Light',
      'Sleep',
      'In Darkness',
      'Part of Love',
      'Familiar',
      'The Law',
      'Open Your Eyes',
      'Alone',
      'Into the Light',
    ],
    remoteImage: true,
  },
];
