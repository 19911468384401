import styled from 'styled-components';
import { AlbumArt } from './AlbumArt';

export function Story({ story }) {
  return (
    <StyledButton href={story.url} target="_blank">
      <AlbumArt src={story.image} $ratio="3:4" />
      <AlbumName>{story.name}</AlbumName>
      <Tracks>
        {story.released === 'Coming Soon'
          ? 'Coming Soon'
          : story.released.split(', ')[1]}
      </Tracks>
    </StyledButton>
  );
}

const StyledButton = styled.a`
  border: none;
  background: none;
  text-align: left;
  padding: 0;
  margin: 0;
  appearance: none;
  outline: none;
  text-decoration: none;
  ${({ onClick }) => !!onClick && `cursor: pointer`};
`;

const Tracks = styled.div`
  font-size: 0.8rem;
  color: #999;

  @media screen and (max-width: 450px) {
    font-size: 1rem;
  }
`;

const AlbumName = styled.div`
  font-size: 0.9rem;
  text-align: left;
  color: #fff;

  @media screen and (max-width: 450px) {
    font-size: 1.1rem;
  }
`;
