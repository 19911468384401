import { memo, useContext, useEffect, useRef } from 'react';
import styled from 'styled-components';
import { PiPauseFill, PiPlayFill } from 'react-icons/pi';
import { PlayerContext } from './PlayerProvider';
import { useState } from 'react';
import { tokenize } from '../albums';
import { AlbumArt } from './AlbumArt';

const PlayerStyled = styled.div`
  position: fixed;
  bottom: 10px;
  width: calc(100% - 20px);
  left: 50%;
  max-width: 780px;
  transform: translateX(-50%);
  display: flex;
  flex-direction: column;
  gap: 10px;
  justify-content: center;
  align-items: center;
  padding: 10px;
  background-color: #292929;
  border-radius: 10px;
  color: #fff;
  user-select: none;

  audio {
    display: none;
  }

  progress {
    width: 100%;
    height: 5px;
    cursor: pointer;
    border: none;
    font-size: 0;
    border-radius: 10px;
  }

  progress::-webkit-progress-bar {
    background-color: #f1f1f1;
    border-radius: 10px;
  }

  progress::-webkit-progress-value {
    background-color: #27c;
    border-radius: 10px;
  }
`;

const PlayerInner = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  max-width: 800px;
  column-gap: 15px;
`;

const PlayerLeft = styled.button`
  border: none;
  background: none;
  text-align: left;
  padding: 0;
  margin: 0;
  appearance: none;
  outline: none;
  cursor: pointer;
  display: flex;
  align-items: center;
  column-gap: 15px;
  color: #fff;
`;

const PlayerInfo = styled.div`
  display: flex;
  flex-direction: column;
  column-gap: 5px;
`;

const PlayerTrack = styled.div`
  font-size: 14px;
  font-weight: 600;
`;

const PlayerAlbum = styled.div`
  font-size: 12px;
  color: #999;
`;

const PlayerRight = styled.div`
  display: flex;
  column-gap: 10px;
`;

const PlayButton = styled.button`
  border: none;
  background-color: transparent;
  cursor: pointer;
  font-size: 1.5rem;
  color: #27c;
  appearance: none;
  padding: 0;
  margin: 0;
  outline: none;
`;

const PauseButton = styled.button`
  border: none;
  background-color: transparent;
  cursor: pointer;
  font-size: 1.5rem;
  color: #27c;
  padding: 0;
  margin: 0;
`;

const AlbumArtWrapper = styled.div`
  width: 50px;
  flex-shrink: 0;
`;

const Placeholder = styled.div`
  height: 100px;
`;

export const Player = memo(({ album: activeAlbum, setAlbum }) => {
  const { song, setSong, playing, setPlaying } = useContext(PlayerContext);
  const [progress, setProgress] = useState(0);
  const ref = useRef();
  const barRef = useRef();
  const mouseRef = useRef();

  const { album, track } = song || {};

  const albumArt = song
    ? !album.remoteImage
      ? `/music/${tokenize(album.name)}.jpg`
      : `https://art.alexmorrise.com/${tokenize(album.name)}.jpg`
    : '';

  const src = song
    ? `https://audio.alexmorrise.com/${tokenize(album.name)}/${tokenize(
        track
      )}.mp3`
    : '';

  const handleMouseDown = () => {
    mouseRef.current = true;
  };

  const handleTouchStart = handleMouseDown;

  const handleClick = (e) => {
    const rect = e.target.getBoundingClientRect();
    const x = e.clientX - rect.left;
    const width = rect.width;
    const value = x / width;
    ref.current.currentTime = ref.current.duration * value;
  };

  useEffect(() => {
    if (!song) {
      return;
    }

    const songRef = ref.current;

    songRef.src = src;
    songRef.play();
    setProgress(0);
    setPlaying(true);

    const onTimeUpdate = () => {
      if (ref.current.duration === 0) {
        return;
      }

      setProgress(ref.current.currentTime / ref.current.duration || 0);
    };

    const onEnded = () => {
      const songIndex = album.tracks.findIndex((t) => t === track);
      const nextTrack = album.tracks[songIndex + 1];

      if (nextTrack) {
        setSong({ album, track: nextTrack });
      } else {
        setSong(null);
      }

      setProgress(0);
    };

    const onPause = () => {
      setPlaying(false);
    };

    const onPlay = () => {
      setPlaying(true);
    };

    const handleUpdate = (xPosition) => {
      if (
        !barRef.current ||
        !mouseRef.current ||
        !ref.current ||
        !ref.current.duration ||
        isNaN(ref.current.duration)
      ) {
        return;
      }

      const { left, width } = barRef.current.getBoundingClientRect();
      const x = Math.min(left + width, Math.max(left, xPosition));
      const value = (x - left) / width;

      try {
        ref.current.currentTime = ref.current.duration * value;
      } catch (e) {
        debugger;
      }
    };

    const handleMouseMove = (e) => {
      if (!e.buttons) {
        mouseRef.current = false;
        return;
      }

      handleUpdate(e.clientX);
    };

    const handleTouchMove = (e) => {
      handleUpdate(e.touches[0].clientX);
    };

    const handleMouseUp = () => {
      mouseRef.current = false;
    };

    const handleTouchEnd = handleMouseUp;

    document.addEventListener('mousemove', handleMouseMove);
    document.addEventListener('touchmove', handleTouchMove);
    document.addEventListener('mouseup', handleMouseUp);
    document.addEventListener('touchend', handleTouchEnd);
    songRef.addEventListener('timeupdate', onTimeUpdate);
    songRef.addEventListener('ended', onEnded);
    songRef.addEventListener('pause', onPause);
    songRef.addEventListener('play', onPlay);

    return () => {
      songRef.pause();
      songRef.src = '';
      songRef.removeEventListener('timeupdate', onTimeUpdate);
      songRef.removeEventListener('ended', onEnded);
      songRef.removeEventListener('pause', onPause);
      songRef.removeEventListener('play', onPlay);

      document.removeEventListener('mousemove', handleMouseMove);
      document.removeEventListener('touchmove', handleTouchMove);
      document.removeEventListener('mouseup', handleMouseUp);
      document.removeEventListener('touchend', handleTouchEnd);
    };
  }, [album, setPlaying, setSong, song, src, track]);

  if (!song) {
    return null;
  }

  return (
    <>
      <Placeholder />
      <PlayerStyled>
        <audio ref={ref} controls src={song} />
        <progress
          max="1"
          value={progress}
          onMouseDown={handleMouseDown}
          onTouchStart={handleTouchStart}
          onClick={handleClick}
          ref={barRef}
        />
        <PlayerInner>
          <PlayerLeft
            type="button"
            onClick={() => {
              if (album !== activeAlbum) {
                window.scrollTo(0, 0);
              }

              setAlbum(album);
            }}
          >
            <AlbumArtWrapper>
              <AlbumArt src={albumArt} alt={album.name} />
            </AlbumArtWrapper>
            <PlayerInfo>
              <PlayerTrack>{track}</PlayerTrack>
              <PlayerAlbum>{album.name}</PlayerAlbum>
            </PlayerInfo>
          </PlayerLeft>
          <PlayerRight>
            {playing ? (
              <PauseButton onClick={() => ref.current.pause()}>
                <PiPauseFill />
              </PauseButton>
            ) : (
              <PlayButton onClick={() => ref.current.play()}>
                <PiPlayFill />
              </PlayButton>
            )}
          </PlayerRight>
        </PlayerInner>
      </PlayerStyled>
    </>
  );
});
